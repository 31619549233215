import React from "react"
import Feature from "./Feature"
import FeatureLeft from "./FeatureLeft"
import {
  GlobeEuropeAfricaIcon,
  ChatBubbleLeftEllipsisIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/20/solid"

const Features = ({ features }) => {
  return (
    <div>
      {features.map(
        (
          {
            title,
            subtitle,
            image,
            description,
            button_text,
            button_link,
            feature1_name,
            feature1_text,
            feature2_name,
            feature2_text,
            feature3_name,
            feature3_text,
          },
          index
        ) => {
          const featureList = feature1_name
            ? [
                {
                  name: feature1_name,
                  description: feature1_text.richText,
                  icon: GlobeEuropeAfricaIcon,
                },
                {
                  name: feature2_name,
                  description: feature2_text.richText,
                  icon: ChatBubbleLeftEllipsisIcon,
                },
                {
                  name: feature3_name,
                  description: feature3_text.richText,
                  icon: ReceiptPercentIcon,
                },
              ]
            : []

          return index % 2 === 0 ? (
            <Feature
              title={title.text}
              subtitle={subtitle.text}
              description={description.richText}
              image={image.url}
              buttonText={button_text}
              buttonLink={button_link.url}
              featureList={featureList}
            />
          ) : (
            <FeatureLeft
              title={title.text}
              subtitle={subtitle.text}
              description={description.richText}
              image={image.url}
              buttonText={button_text}
              buttonLink={button_link.url}
            />
          )
        }
      )}
    </div>
  )
}

export default Features
