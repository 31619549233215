import React from "react"
import loreallogo from "../../../svg/loreal-logo.svg"
import telefonicaLogo from "../../../svg/telefonica-logo.svg"
import gigasetLogo from "../../../svg/gigaset-logo-color.svg"
import lancomeLogo from "../../../svg/lancome-logo-black.svg"
import kiehlsLogo from "../../../svg/kiehls-logo-black.png"
import petsPremiumLogo from "../../../svg/pets-premium-black.png"

const LogoCloud = ({ heading }) => {
  return (
    <div className="bg-white pb-24 sm:pb-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          {heading}
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={loreallogo}
            alt="Loreal"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={petsPremiumLogo}
            alt="PetsPremium"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={gigasetLogo}
            alt="Gigaset"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
            src={lancomeLogo}
            alt="Lancome"
            width={158}
            height={48}
          />

          <img
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src={kiehlsLogo}
            alt="Kiehls"
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>
  )
}

export default LogoCloud
