import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import Hero from "../components/CustomerServicePage/Hero"
import LogoCloud from "../components/CustomerServicePage/LogoCloud"
import Advantages from "../components/CustomerServicePage/Advantages"
import Features from "../components/CustomerServicePage/Features"
import Stats from "../components/CustomerServicePage/Stats"
import Feature from "../components/CustomerServicePage/Features/Feature"
import CTASection from "../components/CustomerServicePage/CTASection"
import FAQ from "../components/CustomerServicePage/FAQ"
import { formatPrismicLang } from "../utils/formatPrismicLang"

const CustomerServicePage = ({ data }) => {
  const prismicNode = data.allPrismicCustomerServicePage.edges.slice(0, 1).pop()
    .node

  const lang = formatPrismicLang(prismicNode)

  const {
    hero_section,
    stats_title,
    stats_subtitle,
    stats,
    logo_group_heading,
    advantages_title,
    advantages_subtitle,
    advantages,
    features,
    cta_section,
    faq_title,
    faq,
    integration,
  } = prismicNode.data

  return (
    <Layout language={lang} smoothScrolling={true}>
      <Hero {...hero_section[0]} />
      <div id="testimonials">
        <LogoCloud heading={logo_group_heading.text} />
      </div>
      <Advantages
        subtitle={advantages_subtitle.text}
        title={advantages_title.text}
        advantages={advantages}
      />
      <div id="features">
        <Features features={features} />
      </div>
      <Stats
        title={stats_title.text}
        subtitle={stats_subtitle.text}
        stats={stats}
      />
      <Feature
        title={integration[0].title.text}
        subtitle={integration[0].subtitle.text}
        description={integration[0].description.richText}
        image={integration[0].image.url}
        buttonText={integration[0].button_text}
        buttonLink={integration[0].button_link.url}
      />
      <CTASection {...cta_section[0]} />
      <FAQ title={faq_title.text} faqs={faq} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query CustomerServiceQuery($lang: String) {
    allPrismicCustomerServicePage(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          lang
          data {
            hero_section {
              hiring1
              hiring2
              hiring_link {
                url
              }
              description {
                richText
              }
              button_text
              button_link {
                url
              }
              image {
                url
              }
              title {
                text
              }
            }
            stats_title {
              text
            }
            stats_subtitle {
              text
            }
            stats {
              name
              number
            }
            logo_group_heading {
              text
            }
            integration {
              title {
                text
              }
              subtitle {
                text
              }
              image {
                url
              }
              description {
                richText
              }
              button_text
              button_link {
                url
              }
            }
            features {
              title {
                text
              }
              subtitle {
                text
              }
              image {
                url
              }
              feature3_text {
                richText
              }
              feature3_name
              feature2_text {
                richText
              }
              feature2_name
              feature1_text {
                richText
              }
              feature1_name
              description {
                richText
              }
              button_text
              button_link {
                url
              }
            }
            faq_title {
              text
            }
            faq {
              answer {
                richText
              }
              question {
                richText
              }
            }
            cta_section {
              title {
                text
              }
              subtitle {
                text
              }
              button_text
              button_link {
                url
              }
            }
            advantages_title {
              text
            }
            advantages_subtitle {
              text
            }
            advantages {
              name {
                text
              }
              description {
                text
              }
            }
          }
        }
      }
    }
  }
`

export default CustomerServicePage
