import React from "react"
import { PrismicRichText } from "@prismicio/react"

const Feature = ({
  title,
  subtitle,
  description,
  image,
  buttonText,
  buttonLink,
  featureList,
}) => {
  return (
    <div className="overflow-hidden bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 flex items-center">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 mt-0 text-primary">
                {subtitle}
              </h2>
              <p className="mt-2 text-3xl leading-none font-bold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                <PrismicRichText field={description} />
              </p>
              {featureList && featureList.length > 0 ? (
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {featureList.map(feature => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">
                        <PrismicRichText field={feature.description} />
                      </dd>
                    </div>
                  ))}
                </dl>
              ) : (
                <div className="mt-8 flex">
                  <a
                    href={buttonLink}
                    className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white hover:text-white shadow-sm hover:bg-primary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  >
                    {buttonText}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-center">
            <img src={image} alt="Product screenshot" className="w-auto" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feature
