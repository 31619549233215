import React from "react"
import {
  ClockIcon,
  BanknotesIcon,
  UsersIcon,
} from "@heroicons/react/24/outline"

const advantagesIcons = [ClockIcon, UsersIcon, BanknotesIcon]

const Advantages = ({ title, subtitle, advantages }) => {
  return (
    <div className="bg-white pb-20 sm:pb-26">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {title}
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">{subtitle}</p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {advantagesIcons.map((Icon, idx) => (
              <div key={idx} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-primary">
                    <Icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {advantages[idx].name.text}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">
                    {advantages[idx].description.text}
                  </p>
                  {/* <p className="mt-6">
                    <a
                      href={feature.href}
                      className="text-sm font-semibold leading-6 text-primary hover:text-primary-dark"
                    >
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p> */}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default Advantages
