import React from "react"

const CTASection = ({ title, subtitle, button_text, button_link }) => {
  return (
    <div className="flex justify-center sm:mx-6 lg:mx-8">
      <div className="bg-gray-200 w-full px-6 sm:px-0 max-w-[76rem] sm:rounded-lg my-10">
        <div className="px-6 py-20 sm:px-6 sm:py-26 lg:px-8">
          <div className="mx-auto max-w-3xl text-center">
            <h2 className="text-3xl leading-tight font-bold tracking-tight text-gray-900 sm:text-4xl">
              {title.text}
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              {subtitle.text}
            </p>
            <div className="mt-10 flex items-center justify-center">
              <a
                href={button_link.url}
                className="rounded-md bg-primary hover:bg-primary-hover hover:text-white px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm"
              >
                {button_text}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTASection
